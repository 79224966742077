@function toRem($a) {
    @return ($a/37.5) + rem;
}
.business{
    padding:toRem(14);
    .business-info{
        padding: toRem(14);
        background-color: #fff;
        .title{
            text-align: center;
            font-size: toRem(16);
            font-weight: bold;
            margin-bottom: toRem(20);
        }
        .info-item{
            margin-bottom: toRem(10);
            display: flex;
            justify-content: space-between;
            span:nth-child(1){
                margin-right: toRem(20);
            }
            span:nth-child(2){
                flex:1;
                text-align:right;
            }
        }
        .shop-logo{
            margin-bottom: toRem(10);
            div{
                margin-bottom: toRem(10);
            }
            img{
                width: toRem(60);
                height: toRem(60);
                border-radius: 50%;
            }
        }
        .shop-business{
            margin-bottom: toRem(10);
            div{
                margin-bottom: toRem(10);
            }
            .license{
                width: toRem(170);
                height: toRem(100);
            }
        }
        .audit{
            margin-top: toRem(20);
            .adm-radio{
                margin-right: toRem(20);
            }
            .reject{
                margin-top:toRem(15);
            }
            .btn{
                display: flex;
                justify-content: center;
                margin-top: toRem(20);
            }
        }
    }
}